<template>
	<div>

		<el-dialog title="巡访记录详情" class="cus_dialog" :visible.sync="dialogshow" :close-on-click-modal="false"
			:modal-append-to-body="false" :destroy-on-close="true" @close="closeDialog" width="1000px">
			<div slot="title" class="flexBetween">
				<span style="color: #ffffff;"><span style="font-weight: 700">{{ EditItem.username }}</span> 巡访记录详情</span>
			</div>
			<div>

				<div v-if="EditItem" class="cellGroupBarTitle flexStart" style="margin-top:10px;">
					<span></span>
					<span style="font-weight: 400">巡访详情</span>
				</div>
				<table class="dttable" v-if="EditItem">
					<tr>
						<td class="tdheader">巡访教师</td>
						<td style="width:30%">{{ EditItem.username }}</td>
						<td class="tdheader">巡访日期</td>
						<td style="width:30%">{{ EditItem.visit_date }}</td>
					</tr>

					<tr>
						<td class="tdheader">巡访学生</td>
						<td colspan="3">
							<table class="dttable">
								<tr v-for="(stu, i) in EditItem.stu_list " :key="i">
									<td>{{ stu.stu_name }}</td>
									<td>{{ stu.stu_code }}</td>
									<td>{{ stu.class_name }}</td>
									<td>{{ stu.firm_name }}</td>

								</tr>

							</table>


						</td>

					</tr>
					<tr>
						<td class="tdheader">企业名称</td>
						<td>
							{{ EditItem.enter_name }}
						</td>
						<td class="tdheader">岗位</td>
						<td>
							{{ EditItem.job }}
						</td>
					</tr>
					<tr>
						<td class="tdheader">巡访形式</td>
						<td>
							{{ EditItem.visit_form_label }}
						</td>
						<td class="tdheader">巡访情况</td>
						<td>
							{{ EditItem.situation_label }}
						</td>
					</tr>

					<tr>
						<td class="tdheader">巡访小结</td>
						<td colspan="3" style="word-break: break-all;">
							{{ EditItem.visit_summary }}
						</td>

					</tr>
					<tr>
						<td class="tdheader">附件</td>
						<td colspan="3">
							<div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
								v-for="(img, idx) in EditItem.fieldname" :key="idx">
								<el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
									:preview-src-list="[img.fileUrl || img.src]" fit="cover" :z-index="3000"></el-image>

								<div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)"><i
										class="el-icon-remove"></i></div>

							</div>

							<div style="display:inline-block;vertical-align: top;" v-if="EditItem.is_draft == 1">

								<el-upload style="display: inline-block; margin-right: 20px" action=""
									:http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false"
									name="image">
									<div class="imgaddbtn">
										＋
									</div>
								</el-upload>




							</div>
						</td>



					</tr>
					<tr v-if="EditItem.fieldwork != null && false">
						<td class="tdheader">是否外勤</td>
						<td colspan="3">
							{{ EditItem.fieldwork == 1 ? '是' : '否' }}
						</td>

					</tr>
				</table>
				<div v-if="EditItem && EditItem.audit_status>0" class="cellGroupBarTitle flexStart"
					style="margin-top:20px;">
					<span></span>
					<span style="font-weight: 400">审批详情</span>
				</div>
				<table class="dttable" v-if="EditItem && EditItem.audit_status>0">
					<tr>
						<td class="tdheader">审批人</td>
						<td colspan="3">
							{{ EditItem.audit_username }}
						</td>
					</tr>
					<tr>
						<td class="tdheader">审批结果</td>
						<td colspan="3">
							<el-tag type="success" v-if="EditItem.audit_status == 1" size="small">审批通过</el-tag>
							<el-tag type="danger" v-if="EditItem.audit_status == 2" size="small">审批拒绝</el-tag>
							<!-- <div>
								<el-image style="width: 200px; height: 100px" :src="EditItem.sign_url"
									:preview-src-list="[EditItem.sign_url]" img="cover" :z-index="3000"></el-image>
							</div> -->
						</td>
					</tr>
					<tr>
						<td class="tdheader">审批评语</td>
						<td colspan="3">
							{{ EditItem.audit_remark }}
						</td>
					</tr>
					<tr>
						<td class="tdheader">审批时间</td>
						<td colspan="3">
							<el-tag type="info" v-if="EditItem.audit_date" size="small">{{ EditItem.audit_date }}</el-tag>
						</td>

					</tr>
					<tr v-if="$store.state.pmid != 214">
						<td class="tdheader">审批人签名</td>
						<td colspan="3">
							<el-image v-if="EditItem.sign_url" :src="EditItem.sign_url"
								:preview-src-list="[EditItem.sign_url]" fit="contain"
								style="height:50px;margin-left:10px;"></el-image>
						</td>
					</tr>
				</table>




			</div>



			<div v-if="(!EditItem.is_draft) && (EditItem.audit_status != 1)" slot="footer" class="flexEnd"
				style="width: 100%;">
				<span style="white-space: nowrap;">审核意见：</span>
				<el-input v-model="spword" size="small" type="textarea" :autosize="{ minRows: 1 }" maxlength="200"
					placeholder="写点评语吧,审核驳回时请填写驳回原因哦~" style="margin-right: 20px;"></el-input>
				<el-button v-if="xunfang_bohui" type="danger" @click="submitForm(2)" size="small">审核驳回</el-button>
				<el-button type="success" @click="submitForm(1)" size="small">审核通过</el-button>

			</div>




		</el-dialog>
	</div>
</template>

<script>

export default {
	components: {},
	props: ["id", "xunfang_bohui"],
	data() {
		return {
			dialogshow: true,
			userinfo: null,
			EditItem: { is_draft: 0, visit_summary: '' },
			process_code: "leave_1",
			needChoose: false,//是否需要选择下级
			choosedUser: [],//已选择的用户.
			spword: "",
			listForm: [],
			listState: [],

		}

	},
	mounted() {
		let _this = this
		this.getAllPickerVal().then(rr => {
			_this.getData()
			_this.getTeacheInfo()
		})

	},
	methods: {
		getTeacheInfo() {
			return new Promise(resolve => {
				this.$http.post("/api/get_teacher_info").then(res => {
					this.userinfo = res.data
					resolve()
				})
			})
		},
		getData() {
			this.$http.post("/api/teacher_visit_info", { id: this.id }).then(res => {
				res.data.fieldname = JSON.parse(res.data.fieldname)
				res.data.stu_list = JSON.parse(res.data.stu_list)
				res.data.visit_form_label = this.listForm[res.data.visit_form].param_desc
				res.data.situation_label = this.listState[res.data.situation].param_desc
				this.EditItem = res.data
				// console.log(195195,this.EditItem)
			})

		},
		getAllPickerVal() {
			return new Promise(resolve => {
				//巡访形式&&巡访情况
				this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
					let listForm = [], listState = [];
					res.data.map((item, index, arr) => {
						if (item.param_type == 'VISIT_FORM') {
							listForm.push(item)

						}
						if (item.param_type == 'VISIT_SITUATION') {
							listState.push(item)

						}
					})

					this.listForm = listForm
					this.listState = listState

					resolve()
				})

			})
		},
		closeDialog() {
			this.$$parent(this, "dialogshow", false);
		},
		uploadFiles(e) {

			this.EditItem.fieldname.push(e)
		},


		preCheckData(e) {

			let DataInfo = this.EditItem;



			if (!DataInfo.visit_summary || DataInfo.visit_summary.length < 100) {

				this.$message({
					type: 'error',
					message: '巡访小结必须大于100字'
				});
				return false;
			}


		},
		removeImg(idx) {
			this.EditItem.fieldname.splice(idx, 1)
		},

		isChooseuser(e) {//设置是否需要选人
			this.needChoose = e
		},
		chooseUser(e) {//设置选择的人

			this.choosedUser = e

		},
		submitForm(st) {
			this.getTeacheInfo().then(rr => {
				console.log(269269269, this.userinfo.sign_img)
				if (this.$store.state.pmid != 214&&!this.userinfo.sign_img || this.userinfo.sign_img == '') {
					this.$alert("请先在小程序设置手写签名！")
					return
				}
				if (!this.spword && st == 2) {
					this.$alert("驳回时请填写驳回评语~")
					return
				}
				this.$confirm('确定审核?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					if (!this.taped) {
						this.taped = 1
						setTimeout(() => {
							this.taped = 0
						}, 3000);
					} else {
						return
					}

					this.$http.post("/api/teacher_visit_audit", {
						id: this.EditItem.id,
						audit_status: st,
						audit_remark: this.spword,
						sign_url: this.userinfo.sign_img
					}).then(res => {
						this.$message({
							type: 'success',
							message: '审核成功!'
						});
						this.$$parent(this, "getList");
						this.$$parent(this, "dialogshow", false);


					})



				})

			})

		}
	},
}
</script>

<style scoped>
.imgaddbtn {
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	border: 1px dotted #ccc;
	font-size: 30px;
	cursor: pointer;
}

.imgremove {
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;

	cursor: pointer;
	position: absolute;
	top: -5px;
	right: -5px;
	color: #ccc;
}

.imgremove:hover {
	color: orange;
}

.cellGroupBarTitle {
	margin-bottom: 10px;
}

.cellGroupBarTitle span:nth-of-type(2) {
	color: #2469f3;
}
</style>
